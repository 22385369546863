/* IMPORT */
import Channels from './/index.js';
/* MAIN */
const channels = new Channels({
  r: 0,
  g: 0,
  b: 0,
  a: 0
}, 'transparent');
/* EXPORT */
export default channels;