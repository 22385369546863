import * as util from './util.js';
export { arrows, setArrows };
var arrows = {
  normal,
  vee,
  undirected
};
function setArrows(value) {
  arrows = value;
}
function normal(parent, id, edge, type) {
  var marker = parent.append('marker').attr('id', id).attr('viewBox', '0 0 10 10').attr('refX', 9).attr('refY', 5).attr('markerUnits', 'strokeWidth').attr('markerWidth', 8).attr('markerHeight', 6).attr('orient', 'auto');
  var path = marker.append('path').attr('d', 'M 0 0 L 10 5 L 0 10 z').style('stroke-width', 1).style('stroke-dasharray', '1,0');
  util.applyStyle(path, edge[type + 'Style']);
  if (edge[type + 'Class']) {
    path.attr('class', edge[type + 'Class']);
  }
}
function vee(parent, id, edge, type) {
  var marker = parent.append('marker').attr('id', id).attr('viewBox', '0 0 10 10').attr('refX', 9).attr('refY', 5).attr('markerUnits', 'strokeWidth').attr('markerWidth', 8).attr('markerHeight', 6).attr('orient', 'auto');
  var path = marker.append('path').attr('d', 'M 0 0 L 10 5 L 0 10 L 4 5 z').style('stroke-width', 1).style('stroke-dasharray', '1,0');
  util.applyStyle(path, edge[type + 'Style']);
  if (edge[type + 'Class']) {
    path.attr('class', edge[type + 'Class']);
  }
}
function undirected(parent, id, edge, type) {
  var marker = parent.append('marker').attr('id', id).attr('viewBox', '0 0 10 10').attr('refX', 9).attr('refY', 5).attr('markerUnits', 'strokeWidth').attr('markerWidth', 8).attr('markerHeight', 6).attr('orient', 'auto');
  var path = marker.append('path').attr('d', 'M 0 5 L 10 5').style('stroke-width', 1).style('stroke-dasharray', '1,0');
  util.applyStyle(path, edge[type + 'Style']);
  if (edge[type + 'Class']) {
    path.attr('class', edge[type + 'Class']);
  }
}