import * as d3 from 'd3';
import * as _ from 'lodash-es';
import { addLabel } from './label/add-label.js';
import * as util from './util.js';
export { createNodes, setCreateNodes };
var createNodes = function (selection, g, shapes) {
  var simpleNodes = g.nodes().filter(function (v) {
    return !util.isSubgraph(g, v);
  });
  var svgNodes = selection.selectAll('g.node').data(simpleNodes, function (v) {
    return v;
  }).classed('update', true);
  svgNodes.exit().remove();
  svgNodes.enter().append('g').attr('class', 'node').style('opacity', 0);
  svgNodes = selection.selectAll('g.node');
  svgNodes.each(function (v) {
    var node = g.node(v);
    var thisGroup = d3.select(this);
    util.applyClass(thisGroup, node['class'], (thisGroup.classed('update') ? 'update ' : '') + 'node');
    thisGroup.select('g.label').remove();
    var labelGroup = thisGroup.append('g').attr('class', 'label');
    var labelDom = addLabel(labelGroup, node);
    var shape = shapes[node.shape];
    var bbox = _.pick(labelDom.node().getBBox(), 'width', 'height');
    node.elem = this;
    if (node.id) {
      thisGroup.attr('id', node.id);
    }
    if (node.labelId) {
      labelGroup.attr('id', node.labelId);
    }
    if (_.has(node, 'width')) {
      bbox.width = node.width;
    }
    if (_.has(node, 'height')) {
      bbox.height = node.height;
    }
    bbox.width += node.paddingLeft + node.paddingRight;
    bbox.height += node.paddingTop + node.paddingBottom;
    labelGroup.attr('transform', 'translate(' + (node.paddingLeft - node.paddingRight) / 2 + ',' + (node.paddingTop - node.paddingBottom) / 2 + ')');
    var root = d3.select(this);
    root.select('.label-container').remove();
    var shapeSvg = shape(root, bbox, node).classed('label-container', true);
    util.applyStyle(shapeSvg, node.style);
    var shapeBBox = shapeSvg.node().getBBox();
    node.width = shapeBBox.width;
    node.height = shapeBBox.height;
  });
  var exitSelection;
  if (svgNodes.exit) {
    exitSelection = svgNodes.exit();
  } else {
    exitSelection = svgNodes.selectAll(null); // empty selection
  }
  util.applyTransition(exitSelection, g).style('opacity', 0).remove();
  return svgNodes;
};
function setCreateNodes(value) {
  createNodes = value;
}